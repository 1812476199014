<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-right uk-margin">
            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('mainModal')">Create</button>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Testimonial</th>
                            <th>Fullname</th>
                            <th>Role</th>
                            <th class="uk-text-center">Image</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{preview(data.testimonial)}}</td>
                            <td>{{data.fullname}}</td>
                            <td>{{data.role}}</td>
                            <td class="uk-text-center">
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.image)">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                                <span class="uk-label uk-label-success" v-if="data.active">Active</span>
                                <span class="uk-label uk-label-danger" v-else>Inactive</span>
                            </td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                        <li class="uk-nav-divider"></li>
                                        <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
            <div class="uk-modal-header">
              <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Fullname <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('fullname')}"
                    name="fullname"
                    type="text"
                    v-model="form.fullname"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('role')}"
                    name="role"
                    type="text"
                    v-model="form.role"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('role')">{{ errors.first('role') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Testimonial <b class="uk-text-danger">*</b></label>
                <textarea
                    class="uk-textarea"
                    :class="{'uk-form-danger': errors.has('testimonial')}"
                    rows="6"
                    name="testimonial"
                    type="text"
                    v-model="form.testimonial"
                    v-validate="'required'"></textarea>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('testimonial')">{{ errors.first('testimonial') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-right">Image </label><br>
                <img v-if="form.image && form.image != ''" :src="`${form.image}`" alt="" class="uk-margin-bottom" width="200">
                    <input
                        name="image"
                        type="file"
                        ref="image"
                        class="uk-input"
                        placeholder="Upload Image">
              </div>
              <div class="uk-margin">
                  <input class="uk-checkbox" type="checkbox" v-model="form.active"> Active
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
            </div>
          </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data ?</b></div>
                <div class="uk-modal-body" >
                    <span>Fullname: {{form.fullname}}</span><br>
                    <span>Role: {{form.role}}</span><br>
                    <span>Testimonial: <br>{{form.testimonial}}</span>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1
            },
            form: {
                testimonial: '',
                fullname: '',
                role: '',
                active: true,
                image: null
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getTestimonial: 'marketing/getTestimonial',
            showTestimonial: 'marketing/showTestimonial',
            deleteTestimonial: 'marketing/deleteTestimonial',
            createTestimonial: 'marketing/createTestimonial',
            updateTestimonial: 'marketing/updateTestimonial',
            uploadImageApi: 'file/uploadImage'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getTestimonial(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        dataExist(data) {
            if (!data) {
                return false;
            } else if (data.length < 1) {
                return false;
            } else {
                return true;
            }
        },
        resetForm() {
            this.form = {
                testimonial: '',
                fullname: '',
                role: '',
                active: true,
                image: null
            };
            this.isEdit = false;
            this.$refs.image.value = '';
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form = data;
                    delete this.form._id;
                }
                if (edit) {
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                await this.uploadImage();

                const response = this.isEdit
                    ? await this.updateTestimonial(this.form)
                    : await this.createTestimonial(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async uploadImage() {
            var files = this.$refs.image.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'testimonial-image', category: 'testimonial', data: formData });
                this.form.image = result.file_url;
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteTestimonial(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
    },
};
</script>
